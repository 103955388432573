import toast from "react-hot-toast";
import * as types from "./actionTypes";
import { apiLoadingStart, apiLoadingStop } from "../global";
import ApiList from "../../webservice/ApiList";

export const resetStore = () => ({
  type: types.RESET_STORE_USER,
});

export const loginAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.LOGIN_USER_REQUEST });

    try {
      const response = await ApiList.userAuthLogin(request);

      if (response?.token) {
        const accessToken = `${response.token}`;
        localStorage.setItem("accessToken", accessToken);
        dispatch({ type: types.LOGIN_USER_SUCCESS, payload: response });
        window.location.reload();
        dispatch(getProfileAction());
      } else {
        // Handle error directly inside the function
        dispatch({
          type: types.LOGIN_USER_ERROR,
          payload: "There are some problems",
        });
        toast.error(response.error.error);
      }
    } catch (error) {
      dispatch({
        type: types.LOGIN_USER_ERROR,
        payload: error?.error || "An error occurred during login",
      });
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
export const getProfileAction = () => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.GETPROFILE_REQUEST });

    try {
      const response = await ApiList.getProfileData();

      if (response) {
        dispatch({
          type: types.GETPROFILE_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({ type: types.GETPROFILE_ERROR, payload: response.status });
      }
    } catch (error) {
      dispatch({
        type: types.GETPROFILE_ERROR,
        payload: error?.error || "An error occurred while fetching the profile",
      });
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

